import React from "react"
import Base from "../../components/templates/Base"

import WelcomeToLoaded from "../../components/SignUp/WelcomeToLoadedForm";

const SignUpPage = ({ location }) => {
  return (
    <Base>
      <WelcomeToLoaded location={location} />
    </Base>
  );
};

export default SignUpPage;