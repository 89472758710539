import React, { useState } from "react"

import EyeOpen from "../../images/eyes-open.svg"
import EyeClosed from "../../images/eyes-closed.svg"

import "./PasswordStyles.scss"

const Password = ({register, error, placeholder, validationMessage, ...inputProps}) => {

  const [viewPassword, setViewPassword] = useState(false)

  const toggleViewPassword = () => {
    setViewPassword(!viewPassword);
  }  

  return (
    <>
      <div className="passInner">
        <input name="password" {...register} type={viewPassword ? "text" : "password"} {...inputProps} placeholder={placeholder ? placeholder : `Password`} tabIndex="0" className="text-input" />
        <div className="iconWrapper" role="button" tabIndex="99" onClick={toggleViewPassword} >
          <img src={viewPassword ? EyeClosed : EyeOpen} className="eyeIcon" alt="Show/Hide Password Icon" />
        </div>
      </div>
      {error && <span className="has-errors">{error.message}</span>}
    </>
  )
}

export default Password
