import React, { useRef, useState } from "react"
import queryString from "query-string"

import * as SignUpStyles from "./signup.module.scss"

import { useForm } from "react-hook-form";
import { useStateMachine } from 'little-state-machine'
import { updateDetails } from './updateDetails'
import { navigate } from "gatsby"
import Password from "../elements/PasswordField";
import Spinner from "../elements/Spinner";
import { Link } from "gatsby";

import { isBrowser } from "../helpers/helpers"
import axios from "axios";

const WelcomeToLoaded = ({ location }) => {

  const { actions, state } = useStateMachine({ updateDetails });

  const { register, handleSubmit, watch, formState } = useForm({mode: 'all'});
  const { errors } = formState;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [postErrors, setPostErrors] = useState(false);
  const [showEmailField, setShowEmailField] = useState(false);

  const password = useRef({});
  password.current = watch("password", "");

  const goBack = data => {
    if (isBrowser()) {
      navigate(-1)
    }
  };

  const onSubmit = data => {
    setIsSubmitting(true);
    actions.updateDetails(data);

    const names = data.fullName.split(" ")
    const fName = names[0]
    names.shift()
    const sName = names.join(" ")

    const accountInfo = {
      "firstName": fName,
      "lastName": sName,
      "email": data.email,
      "password": data.password,
      "confirmPassword": data.passwordConf,
      "phone": data.contactNumber.split(' ').join('')
    }

    axios.post(`${process.env.GATSBY_APIURL}/a/Account/Register`, accountInfo)
    .then(async (response) => {
      setIsSubmitting(false);

			if (isBrowser()) {

        // Retrieve potential invite flow parameters from the url and redirect
        const search = location.search ? queryString.parse(location.search) : false;
        const { inviteToken, companyName } = queryString.parse(location.search);
        
        // Timeout due to some adblockers blocking the redirect on sign-up within the google tag manager datalayer
        const timeout = setTimeout(() => signUpRedirect(), 1000)
        
        function signUpRedirect() {
          if (inviteToken) {
            window.location.href = `/App#?inviteToken=${inviteToken}&companyName=${companyName}&${new URLSearchParams(response.data).toString()}`;
          } else {
            window.location.href = `/App#?${new URLSearchParams(response.data).toString()}`;
          }
        }

        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'ManualPageview',
            manualPageviewPage: '/sign-up/welcome/complete',
            eventCallback: () => {
              clearTimeout(timeout)
              // Once we've tracked the successful sign up with analytics we then
              // redirect into the Loaded app, handle whether or not this is an
              // invite acceptance flow differently than a normal signup because
              // different flows then happen on the Loaded app side too.
              signUpRedirect()
            }
          })
				} 
				
			}
    })
    .catch(function (error) {
      setIsSubmitting(false);
      if (error.response) {
				let newItems = [];
				let usernameTakenFlag = false
        if (error.response.status === 400) {

          // Flatten model error objects into a single level list of problems,
          // the object returned is keyed on model fields with an array of
          // messages for each.
          const items = Object.keys(error.response.data.modelState)
            .map(key => error.response.data.modelState[key])
            .flat()
					
					items.forEach((item, index) => {

						if (item.indexOf('is already taken') !== -1) {
							if (usernameTakenFlag === false && item.indexOf('is already taken') > 0) {
								usernameTakenFlag = true;
								item = item.replace('Name', 'Email');
								newItems.push(item);
								setShowEmailField(true);
							}
						} else {
							newItems.push(item);
						}

					});

				}

				setPostErrors(newItems);

      } else if (error.request) {
        // console.log(error.request);
      } else {
        // console.log('Error', error.message);
      }
      // console.log(error.config);
    });

    // navigate("/welcome");
  };

  const onError = (errors, e) => {
    setIsSubmitting(false);
    // console.log(errors, e);
  }

  let postErrorArray = [];
  if (postErrors) {

    for (const [key, value] of Object.entries(postErrors)) {
      postErrorArray.push(`${value}`);
    }
  }

	if (!state.signUpDetails.email) goBack();

  return (
    <section className={SignUpStyles.signUp}>
        <div className={SignUpStyles.innerDiv}>

        <div className="component">
          <h1>Welcome to Loaded</h1>
          <div className={SignUpStyles.subText}>We can't wait to get you up and running</div>

          <form id="welcome-sign-up" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className={SignUpStyles.emailForm}>
            <div className={SignUpStyles.formDiv}>
              <div className={`${SignUpStyles.tCenter} ${SignUpStyles.mb16}`}>
								<input type="email" placeholder="Email Address" defaultValue={state.signUpDetails.email} className="text-input" {...register("email", { required: true, pattern: "[^@\s]+@[^@\s]+\.[^@\s]+" } )} style={{display: showEmailField ? "block" : "none" }} />
								{errors.email && <span className={SignUpStyles.hasErrors}>A valid email address is required</span>}

                {/* <input type="text" id="username" name="username" value={state.signUpDetails.email} onChange={e => updateUsername(e.target.value)} onBlur={e => updateUsername(e.target.value)} style={{display: "none"}} /> */}
                <div style={{display: showEmailField ? "none" : "block" }} >{state.signUpDetails.email}</div>
              </div>
              <div className={SignUpStyles.inputWrap}>
                <input tabIndex="0" type="text" placeholder="Full Name" className="text-input" {
                    ...register("fullName", {
                      required: {
                        value: true,
                        message: 'Full name required'
                      },
											...(state.signUpDetails.fullName ? {value: state.signUpDetails.fullName} : {}),
                      validate: (value) => {
                        // const regex = new RegExp(/\b[^\d\s]+\b/g);
                        const matches = value.match(/\b[^\d\s]+\b/g);
                        return (matches.length >= 2) || "Please ensure you use your full name"
                      }
                    })
                  }
                />
                {errors.fullName && <span className={SignUpStyles.hasErrors}>{errors.fullName.message}</span>}
              </div>
              <div className={SignUpStyles.inputWrap}>
                <input tabIndex="0" type="text" placeholder="Contact Number" className="text-input" {
                  ...register("contactNumber", {
                    required: {
                      value: true,
                      message: 'Contact Number is required'
                    },
                    validate: {
                      //space: (value) =>  !(value.indexOf(' ') >= 0) || "Your contact number cannot contain spaces",
                      match: (value) => {
                        // console.log(value);
                        const regex = new RegExp(/^(?=.*[0-9])[- +()0-9]+$/i);
                        return regex.test(value) || "Your contact number can only have numbers, + and ()"
                      },
                    }
                 })} />
                {errors.contactNumber && <span className={SignUpStyles.hasErrors}>{errors.contactNumber.message}</span>}

              </div>
              <div className={SignUpStyles.inputWrap}>
                <Password placeholder="Password" id="new-password"  autoComplete="new-password" register={
                  register("password", {
                    required: {
                      value: true,
                      message: 'Password is required'
                    },
                    minLength: {
                      value: 6,
                      message: 'Please ensure your password has at least 6 charaters'
                    }
                  }
                  )} error={errors.password} validationMessage="A password is required and has to be at least 6 charaters long" />
              </div>
              <div className={SignUpStyles.inputWrap}>
                  <Password placeholder="Confirm Password" id="new-password-confirm" autoComplete="new-password" register={register("passwordConf", {
                    required: {
                      value: true,
                      message: 'Password confirmation is required'
                    },
                    minLength: {
                      value: 6,
                      message: 'Please ensure your password has at least 6 charaters'
                    },
                    validate: {
                      match: (value) => {
                        // console.log(value);
                        // console.log(password)
                        return value === password.current || "Password's do not match"
                      },
                    }
                  })
                } validationMessage="Passwords do not match" error={errors.passwordConf} />
              </div>
              {postErrorArray.length > 0 &&
                <div className={SignUpStyles.postErrors}>
                  {
                    postErrorArray.map((item, index) => {
											return (
												<div key={index}>{item}</div>
											)
                    })
                  }
                </div>
              }
              <div className={SignUpStyles.inputWrap}>
                <button type="submit" className={SignUpStyles.button}><span className={SignUpStyles.buttonInner}>{isSubmitting && <Spinner /> }Create Account &rarr;</span></button>
                <div className={SignUpStyles.prevButton} role="button" tabIndex="0" onClick={goBack} >
                  &larr; Go Back
                </div>
              </div>
            </div>

          </div>
          </form>

          <div className={SignUpStyles.disclaimer}>
            By creating a loaded account agree to the <Link to="/end-user-licence-agreement">End User Licence Agreement</Link> and <Link to="/privacy-policy">Privacy Policy.</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WelcomeToLoaded;
